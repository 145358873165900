<template>
  <div class="order">
    <div class="top">
      <el-menu
        default-active="1"
        mode="horizontal"
        active-text-color="#545c64">
        <el-menu-item index="1">订单管理</el-menu-item>
      </el-menu>
      <div class="search">
        <el-form ref="form" :model="form" inline>
          <el-form-item>
            <el-select v-model="form.type" placeholder="请选择类型" clearable>
              <el-option label="识别" :value="1"></el-option>
              <el-option label="充值" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%; margin-top:20px"
    >
      <el-table-column
        prop="id"
        label="订单ID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="template_id"
        label="模板ID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="用户"
        min-width="200">
      </el-table-column>
      <el-table-column
        prop="desc"
        label="描述"
        min-width="200">
      </el-table-column>
      <el-table-column
        prop="type"
        label="变动类型"
        width="200">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 1">识别</el-tag>
          <el-tag v-if="scope.row.type == 2" type="success">充值</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="num"
        label="变动次数"
        width="200">
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
        width="250">
      </el-table-column>
      <el-table-column
        prop="updated_at"
        label="更新时间"
        width="250">
      </el-table-column>
      <el-table-column
        prop="result"
        label="返回信息"
        min-width="300">
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right;margin-top: 10px"
      background
      :current-page.sync="form.page"
      :page-size="form.size"
      :total="total"
      layout="total, prev, pager, next"
       @current-change="handleCurrentChange"
      >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name:"order",
  data(){
    return {
      form:{
        page:1,
        size:10,
        type:''
      },
      loading:true,
      total:0,
      tableData: []
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.loading = true
      this.$api.getOrderList(this.form).then(res => {
        if(res.status == 200){
          this.total = res.data.count
          this.tableData = res.data.lists
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    onSubmit(){
      this.form.page = 1;
      this.getList()
    },
    handleCurrentChange(val){
      this.form.page = val;
      this.getList()
    }
  }
}
</script>
<style scoped lang='less'>
.top{
  margin-top: -20px;
  position: relative;
  .search{
    padding: 10px 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>